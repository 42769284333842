.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.phone-input-style .PhoneInput {
  display: flex !important;
}

.phone-input-style .PhoneInputCountry {
  display: inline-flex !important;
}

.phone-input-style .PhoneInputInput {
  width: 50%;
  border: transparent;
}

.phone-input-style .PhoneInputInput:focus {
  outline: none !important;
  border: transparent;
}

.date-picker-style .react-datepicker-wrapper {
  width: 100%;
}

.date-picker-style .react-datepicker {
  display: flex !important;
}

.teamBoxModal {
  padding: 30px !important;
  border-radius: 6px !important;
  background-color: white !important;
  width: 100%;
  max-width: 1100px !important;
  max-height: 462px;
  position: absolute !important;
  height: 100%;
  top: 25%;
  left: 0;
  right: 0;
  bottom: auto;
  margin: auto !important;
}

.serv-img span {
  display: block !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (max-width: 1100px) {
  .teamBoxModal {
    max-width: 100% !important;
  }
}

@media (max-width: 840px) {
  .teamBoxModal {
    width: 80%;
    bottom: 5% !important;
    padding: 30px !important;
  }
}

@media (max-width: 487px) {
  .teamBoxModal {
    width: 90%;
    bottom: 5% !important;
    padding: 1.2rem !important;
  }
}
